.wrapper {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 20px 16px;
}
.row {
  display: flex;
  flex-wrap: wrap;
  .col {
    flex-shrink: 0;
    width: 200px;
    height: 50px;
  }
}
