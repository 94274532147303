@import "../../../../node_modules/@maxtropy/central-commons-ui/dist/style/themes/light/color";
@import "../../../../node_modules/@maxtropy/central-commons-ui/dist/components/Paging/index";

.container{
  margin: 12px;
  background: #fff;
  padding: 15px;
  .search{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    .radio{
      :global{
        .ant-radio-button-wrapper:first-child{
          border-radius: 4px 0 0 4px;
        }
        .ant-radio-button-wrapper:last-child{
          border-radius: 0 4px 4px 0;
        }
      }
    }
  }
  .pagination {
    @include paging;
  }
}
