header.header {
  background: #1F293D;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 0;

  .logo {
    display: flex;
    align-items: center;
    margin-right: 20px;

    .logoImg {
      width: 150px;
    }

    .title {
      height: 16px;
      margin-left: 13px;
    }
  }

  .menu {
    height: 50px;
    display: flex;
    background: #1F293D;

    .menuItem {
      height: 50px;
      line-height: 50px;
      padding: 0 20px;

      &:hover {
        background: transparent;
      }
    }

    :global {
      .ant-menu-item-selected {
        color: #fff;
        background: transparent;
      }
    }
  }

  .userName {
    width: auto;
    padding: 0 15px;
    text-align: center;
    height: 50px;
    margin-left: auto;
    color: #eee;
    line-height: 50px;
    cursor: pointer;
  }
}

ul.dropMenu {
  margin-top: -3px;
  overflow: hidden;
  border-radius: 0;
  padding: 0;
  background: #1F293D;

  .dropMenuItem {
    font-size: 12px;
    padding: 11px;

    &:hover {
      color: #fff;
      background: #1890FF;
    }

    .text {
      margin-left: 5px;
      color: rgba(255, 255, 255, 0.7);
    }

    :global {
      svg {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
}
