.wrapper {
  margin: 12px;
  background-color: #FFF;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .formWrapper {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .formItem {
      // margin: 0 150px;
      width: 80%;
      .groupName {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        line-height: 50px;
      }
    }
  }
}