@import "../../../../../node_modules/@maxtropy/central-commons-ui/dist/style/themes/light/color";
@import "../../../../../node_modules/@maxtropy/central-commons-ui/dist/components/Paging/index";

.content{
  padding: 12px;
  .top{
    text-align: center;
    background: #ffffff;
    padding: 30px 0;
    .circle{
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 20px;
      position: relative;
      top: -4px;
      right: 5px;
      box-shadow: 0 0 10px;
    }
    :global{
      .ant-col-6{
        border-right: 1px solid #E8E8E8;
      }
      .ant-col-6:last-child{
        border: 0;
      }
    }
  }
  .container{
    background: #fff;
    padding: 15px;
    margin-top: 12px;
    .search{
      text-align: right;
      .searchInput{
        width: 300px;
        margin-right: 15px;
      }
    }
    .pagination {
      @include paging;
    }
    .action{
      display: flex;
      justify-content: space-between;
    }
  }
}
