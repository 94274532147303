.pageNotAuthorized {
  width: 100%;
  height: calc(100vh - 50px);
  padding: 15px;
  background-color: #f5f5f5;

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color:#fff;
  }

  .logo {
    background: url("../../assets/images/content-forbidden.svg") no-repeat center center;
    background-size: contain;
    width: 70px;
    height: 66px;
    margin-bottom: 15px;
  }

  .text {
    color:#333;
    font-size: 24px;
  }
}
