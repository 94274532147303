.background{
  margin: 12px;
  min-height: calc(100vh - 104px);
  background: #fff;
  padding: 15px;
  .container{
    width: 80%;
    margin: 70px auto 30px;
    .des{
      :global{
        .ant-descriptions-row > th{
          padding-bottom: 0;
        }
        .ant-descriptions-row > td{
          padding-bottom: 30px;
        }
        .ant-descriptions-item-content{
          color: rgba(0, 0, 0, 0.65);
        }
      }
    }
    :global{
      .ant-divider-horizontal.ant-divider-with-text-left::before{
        width: 0;
      }
      .ant-divider-inner-text{
        padding-left: 0;
      }
    }
  }
}
