.name {
  display: inline-block;
  margin-right: 3px;
}

.title {
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  .content {
    // height: 30px;
    font-size: 16px;
    padding: 8px 0;
    display: flex;
    .key {
      color: #666666;
      width: 60px;
    }
  }
}

.modalFooter {
  display: flex;
  align-items: center;
  justify-content: center;
}
