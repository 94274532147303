.select {
  // display: flex;
  :global {
    .ant-select-selector {
      padding-right: 23px;
      .ant-select-selection-search {
        opacity: 0;
        // display: none;
      }
    }
  }
}

.search {
  margin-bottom: 10px;
}