.desc { 
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
  .icon {
    margin-left: 15px;
    margin-right: 8px;
    color: #FAAD14;
  }
}