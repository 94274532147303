.common-table-wrapper {
  table thead tr th {
    font-size: 12px;
    font-weight: 700;
    padding: 12px 0;
    text-align: left;
    border-bottom: 1px solid rgba(59,65,81,.2);
    font-family: sans-serif;
    color: #3b4151;
  }

  table thead tr th {
    font-size: 12px;
    font-weight: 700;
    padding: 12px 0;
    text-align: left;
    border-bottom: 1px solid rgba(59,65,81,.2);
    font-family: sans-serif;
    color: #3b4151;
  }

  table tbody tr td {
    padding: 10px 0 0;
    vertical-align: top;
  }
  
  table tbody tr td:first-of-type {
    min-width: 6em;
    padding: 10px 0;
  }

  > table {
    width: 100%;
    padding: 0 10px;
    border-collapse: collapse;

    .col-header {
      min-width: 98px;
    }

    .col-description {
      width: 99%;
      margin-bottom: 2em;
    }

    .name {
      font-size: 16px;
      font-weight: 400;
      margin-right: .75em;
      font-family: sans-serif;
      color: #3b4151;
    }

    .name.required {
      font-weight: 700;
      span {
        color: red;
        &::after {
          font-size: 10px;
          position: relative;
          top: -6px;
          padding: 5px;
          content: "required";
          color: rgba(255,0,0,.6);
        }
      }
    }

    .type {
      font-size: 12px;
      padding: 5px 0;
      font-family: monospace;
      font-weight: 600;
      color: #3b4151;
    }

    .deprecated {
      font-size: 12px;
      font-style: italic;
      font-family: monospace;
      font-weight: 600;
      color: red;
    }

    .parameter-in {
      font-size: 12px;
      font-style: italic;
      font-family: monospace;
      font-weight: 600;
      color: grey;
    }
    
    .prop-format {
      color: #606060;
    }

    .markdown {
      font-size: 14px;
      font-family: sans-serif;
      color: #3b4151;

      p {
        word-break: break-word;
      }
    }

    .enum {
      i {
        font-style: italic;
      }
    }
  }

  .headers {
    td {
      font-size: 12px;
      vertical-align: middle;
      font-family: monospace;
      font-weight: 600;
      color: #3b4151;    
    }
  }

  .json-tree-wrapper {
    padding: 10px;
    background: rgba(0,0,0,.1);
  }
}
