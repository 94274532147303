.wrapper {
  margin: 12px;
  background-color: #FFF;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  .formWrapper {
    width: 80%;
    position: relative;
    .formItem {
      // max-width: 360px;
      width: 80%;
      margin-bottom: 50px;
      margin-left: 36px;
      label {
        color: #666666;
      }
    }
    .edit {
      max-width: 360px
    }
  }
}
