.table{

}

.header{
  height: 50px;
  padding: 0 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 9px;
  .title {
    color: #000;
    margin: 0 10px;
    font-weight: 500;
    font-size: 16px;
  }
  .children{
    flex: 1;
    padding-left: 20px;
  }
}
