.container {
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
  font-size: 14px;
  color: rgba($color: #000000, $alpha: 0.65);
}

header {
  display: flex;
}

.box {
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  flex: 1;
  margin: 10px;
  height: 98px;
  padding-left: 20px;
  padding-top: 20px;
  background-color: white;
  &Title {
    padding-left: 4px;
    font-size: 14px;
    color: rgba($color: #000000, $alpha: 0.45);
  }
  &Value {
    font-size: 30px;
    color: rgba($color: #000000, $alpha: 0.85);
    font-weight: 500;
  }
  &Status {
    font-size: 28px;
    font-weight: 500;
    color: #1dc499;
    position: relative;
    padding-left: 24px;

    &::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      background: #1dc499;
      box-shadow: 0 0 10px;
      border-radius: 5px;
      top: 50%;
      left: 4px;
      margin-top: -5px;
    }
  }
  .abnormal {
    color: red;
    &::before {
      background: red;
    }
  }
}

.bd {
  background-color: white;
  display: flex;
  margin-top: 20px;
  flex: 1;
  padding: 40px 76px 34px 52px;
}

.left {
  width: calc(100% - 550px);
  margin-right: 30px;
}

.right {
  flex-shrink: 0;
}

.title {
  color: rgba($color: #000000, $alpha: 0.85);
  font-weight: bold;
}

.subTitle {
  color: rgba($color: #000000, $alpha: 0.85);
  margin-top: 20px;
}

.tableTitle {
  font-weight: bold;
  color: #000;
  margin-top: 13px;
  padding-left: 40px;
}

.table {
  margin-top: 13px;
  padding-left: 27px;
  &Header {
    height: 38px;
    background-color: #fafafa;
  }
  &Row {
    height: 50px;
  }
  .rank {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: #f0f2f5;
  }
  .topThree {
    background-color: #1890ff;
    color: white;
  }
}

.selected {
  color: #1a90fe !important;
}
