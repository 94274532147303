.wrapper {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 20px 16px;
  position: relative;
  max-height: 600px;
  overflow-y: auto;
  .formItem {
    min-width: 250px;
    width: 50%;
  }
}

.customTreeRender {
  display: flex;
  align-items: center;
  .switch {
    margin-left: 50px !important;
    margin-right: 5px !important;
  }
}

