@import "../../../../../node_modules/@maxtropy/central-commons-ui/dist/style/themes/light/color";
@import "../../../../../node_modules/@maxtropy/central-commons-ui/dist/components/Paging/index";

.container{
  margin: 12px;
  min-height: calc(100vh - 104px);
  background: #fff;
  padding: 15px;
  .search{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .radio{
      :global{
        .ant-radio-button-wrapper:first-child{
          border-radius: 4px 0 0 4px;
        }
        .ant-radio-button-wrapper:last-child{
          border-radius: 0 4px 4px 0;
        }
      }
    }
  }
  .pagination {
    @include paging;
  }
  .action{
    display: flex;
    justify-content: space-between;
  }
  .backgroundRed{
    background: rgba(255, 189, 199, 1);
    :global {
      td {
        background-color: rgba(255, 189, 199, 1);
      }
    }
  }
  .btn{
    padding: 0;
    line-height: 20px;
    height: 22px;
  }
  :global{
    .ant-table-tbody > tr.ant-table-row:hover > td{
      background: transparent;
    }
  }
}
