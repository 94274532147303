//Font
$sw-font-family: 'Metropolis';
$sw-font-mono  : 'roboto-mono', 'Monaco', 'Menlo', 'Consolas';

//Colors
$sw-primary-color:#FF791A;

$sw-dark-bg1:#333;
$sw-dark-bg2:#515151;
$sw-dark-bg3:#65656A;

$sw-blue-text:#0079b8;
$sw-dark-text:#565656;
$sw-gray-text:#999;
$sw-light-text:#C9C9C9;
$sw-extra-light-text:#FAFAFA;

$sw-light-bg1:#FAFAFA;
$sw-light-bg2:whitesmoke;
$sw-light-bg3:#F2F2F2;
$sw-white:#fff;

$sw-green:#99CC00;
$sw-red:#CC0000;
$sw-soft-red:#ED4337;
$sw-orange:#FF9900;
$sw-info:#47AFE8;
$sw-unknown:#CCCCCC;

$sw-icon-critical:$sw-red;
$sw-icon-error:#FF0000;
$sw-icon-warn:$sw-orange;
$sw-icon-warn-minor:#FFCC00;
$sw-icon-warn-low:#EFE47E;
$sw-icon-good:$sw-green;
$sw-icon-info:$sw-info;
$sw-icon-unknown:$sw-unknown;

//Borders
$sw-border-color:#ccc;
$sw-light-border-color:#eee;
$sw-border-radius:2px;
